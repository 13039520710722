import { render, staticRenderFns } from "./DeliveryZoneEditInfoForm.vue?vue&type=template&id=5ed5aeac&scoped=true"
import script from "./DeliveryZoneEditInfoForm.vue?vue&type=script&lang=js"
export * from "./DeliveryZoneEditInfoForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed5aeac",
  null
  
)

export default component.exports